import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {PartList} from "../components/items/item-list"
import Catalog from "../services/catalog"

class BrowsePartsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parts: [],
    };
  }

  componentWillMount() {
    Catalog.getParts().then(parts => this.setState({parts})).catch(console.error);
  }

  render() {
    const { parts } = this.state;

    return(
      <Layout navTab={2}>
        <SEO title="Part Browser" />
        <PartList items={parts}/>
      </Layout>
    );
  }
}

export default BrowsePartsPage